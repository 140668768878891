html,
body {
padding: 0%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.banner-bawah {
  margin-top: 700px;
  height: auto;
  max-width: 100%;
}


footer {
  display: flex;
  justify-content: center; /* Pusatkan secara horizontal */
  align-items: center; /* Pusatkan secara vertikal */
  background-color: #000066;
  text-align: center;
  color: white;
  font-weight: 700;
  width: 100%;
  height: 60px; /* Tinggi footer, sesuaikan sesuai kebutuhan */
}
p {
  padding-top: 40px;
}

@media (max-width: 768px) {
  .banner-bawah {
    margin-top: 100px;
    height: auto;
    max-width: 100%;
  }

  .footer {
    margin-top: 100px;
  }
  p {
    padding-top: 40px;
  }
}
