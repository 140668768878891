* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.header {
  background-color: #000066;
}

.banner-pirate {
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* .banner-festival {
  width: 90%;
  top: 0px;
  position: absolute;
  left: 42%;
  max-width: 200px;
  height: auto;
  display: block;
  margin: 10 auto;
} */

@keyframes myAnim {
  0% {
    transform: scale(1) translateX(-50%);
  }
  50% {
    transform: scale(1.1) translateX(-50%);
  }
  100% {
    transform: scale(1) translateX(-50%);
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .header {
    height: 280px;
  }
  .banner-pirate {
    width: 90%;
    top: 100px;
    height: auto;
  }
  .banner-festival {
    width: 90%;
    top: 0px;
    position: absolute;
    max-width: 200px;
    height: auto;
    display: block;
    margin: 10 auto;
    left: 22%;
  }
}

/* Tablet small and medium devices (768px - 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .banner-pirate {
    height: auto;
    top: 90px;
    width: 75%;
  }
  .header {
    height: 400px;
  }
  .banner-festival {
    width: 90%;
    top: 0px;
    position: absolute;
    left: 35%;
    max-width: 200px;
    height: auto;
    display: block;
    margin: 10 auto;
  }
}

/* Tablet large devices and above (>=1024px) */
@media (min-width: 1024px) {
  .banner-pirate {
    max-width: 100%;
  }
  .banner-festival {
    width: 90%;
    top: 0px;
    position: absolute;
    left: 43%;
    max-width: 200px;
    height: auto;
    display: block;
    margin: 10 auto;
  }
  .header {
    height: 22%;
  }
}

/* Orientation specific styles */
@media (orientation: landscape) {
  .header {
    height: 790px;
  }
}

/* hp */
/* @media (orientation: portrait) {
  .header {
    height: 350px; 
  }
  .banner-pirate {
    width: 90%;
    top: 130px; 
    height: auto;
  }
  .banner-festival{
    left: 22%;
  }
} */
